@import "mods/reset.css";
@import "mods/var.css";
@import "mods/components.css";

html, body {
    background: #f1f1f1;
    color: var(--color_1);
    width: 100%;
    overflow-x: hidden;
    font-family: "verdana-pro", sans-serif;
    font-size: calc(10px + 2 * (100vw - 320px) / 1080);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    overflow: hidden;
}

p, li {
    line-height: 1.8;
    color: var(--color_1);
    opacity: 0.85;
}


/* header */

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    color: #fff;
    background-color:transparent;
    /*box-shadow: 1px 1px 12px -3px rgba(0, 0, 0, .1);*/
    transition: var(--transition_open);
}

.header-top {
    font-size: calc(5px + 5 * (100vw - 320px) / 1080);
    font-style: italic;
    padding: 10px 50px;
    align-items: center;
    text-align: center;
}

.header-logo{
    width: clamp(130px, 20%, 170px);
    height: auto;
}

.header-logo svg {
    width: 100%;
    height: auto;
}

.header-logo svg path {
    fill: #fff;
}

.header-logo svg .st0 {
    stroke: #fff;
    fill: #fff;
}

.header-bottom {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: calc(var(--gap_padding) / 1.5) var(--container_padding);
    height: var(--header-bottom);
    transition: var(--transition_open);
    align-items: center;
}

.header-nav {
    display: flex;
    align-items: center;
}

.nav-icon {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.nav-icon span {
    color: #fff;
    text-transform: uppercase;
    font-family: var(--font_1);
    font-size: 20px;
}

.nav-icon > div {
    width: 30px;
    height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 10px;
    z-index: 999;
}

.nav-icon > div > div {
    height: 2px;
    width: 100%;
    background-color:#fff;
    transform-origin: center;
    transition: all 0.3s ease-in-out;
}

.nav .bn-close {
    position: absolute;
    right: var(--gap_padding);
    top: var(--gap_padding);
    z-index: 9998;
}

.bn-close {
    width: 20px;
    height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
}

.bn-close:hover > div {
    background-color: var(--color_2);
}

.bn-close > div {
    height: 1.5px;
    width: 100%;
    background-color: #fff;
    transition: all 0.3s ease-in-out;
}

.bn-close > div:first-child {
    transform: translateY(10px) rotate(-45deg);
}

.bn-close > div:last-child {
    transform: translateY(-9px) rotate(45deg);
}

.nav {
    position: fixed;
    left: 0;
    top: 0;
    height: 104vh;
    z-index: 9997;
    width: 100vw;
    pointer-events: none;
}

.nav-list > li:last-child {
    display: none;
}

.nav-wrapper {
    width: clamp(300px, 50%, 600px);
    height: 100%;
    padding: calc(var(--gap_padding) * 3) calc(var(--gap_padding) * 2) calc(var(--gap_padding) * 2);
    z-index: 99999;
    background-color: var(--color_1);
}

.open-nav .nav {
    pointer-events: auto;
}

.nav-list {
    z-index: 1;
}

.nav-list > li:not(.dropdown-li){
    padding-bottom: 30px;
    color: #fff;
}

.dropdown-li {
    padding-bottom: 20px;
}

.sub-title {
    color: var(--color_2);
    font-size: calc(13px + 1 * (100vw - 320px) / 1080);
    font-weight: 600;
}

.nav-list li, .nav-title {
    font-family: var(--font_1);
    font-size: calc(10px + 5 * (100vw - 320px) / 1080);
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 600;
}

.nav-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    color: #fff;
}

.nav-title svg {
    height: calc(5px + 2 * (100vw - 320px) / 1080);
    stroke-width: 2px;
}

.nav-title svg path {
    stroke: #fff;
    fill: none;
}

.sub-title {
    font-size: calc(12px + 3 * (100vw - 320px) / 1080)!important;
}

.sub-wrap {
    visibility: hidden;
    height: 0;
    pointer-events: none;
}

.sub-wrap li {
    margin: calc(13px + 1 * (100vw - 320px) / 1080) 0;
    font-size: calc(13px + 1 * (100vw - 320px) / 1080);
}

.sub-wrap li:not(.sub-title) {
    transition: all .4s ease-in-out;
    color: #fff;
}

.sub-wrap li:not(.sub-title):hover {
    transform: translateX(10px);
}

.sub-wrap li:hover a {
   font-weight: 800;

}


/* au scroll */

.fix .header {
    transform: translateY(calc(-1 * var(--header-top)));
    --header-bottom: clamp(70px, 10vh, 120px);
    background-color: var(--color_1);
}

.fix .header-bottom {
    padding: 15px var(--container_padding);
    height: inherit;
}

.fix .nav {
    padding-top: 20px;
}


/* footer */

#footer {
    margin-top: var(--section_padding);
    border-top: 1px solid rgba(0, 0, 0, .1);
}

#footer .footer-top {
    padding: var(--section_padding) 0;
    display: flex;
    justify-content: space-between;
    gap: 20px;
}


#footer .footer-top > * {
    width: calc(100%/3);
}

#footer > * {
    opacity: 0.8;
}

#footer .footer-rs {
    display: flex;
}

#footer .footer-rs a {
    display: block;
    height: 25px;
    margin-right: 20px;
}

#footer .footer-rs a svg {
    height: 100%;
    width: auto;
}

#footer .footer-rs a:first-child svg .st0 {
    fill: none!important;
    stroke: none;
}

#footer .footer-rs a:nth-child(2) svg path {
    stroke: none;
}

.footer-middle ul {
    display: flex;
    gap: 100px;
}

.footer-middle ul li, .footer-right ul li, .footer-address > * {
    padding: 8px 0;
    font-size: calc(10px + 2 * (100vw - 320px) / 1080);
}

#footer .title-footer {
    display: inline-block;
    margin-bottom: 50px;
    font-weight: 600;
    font-family: "freight-neo-pro", sans-serif;
    text-transform: uppercase;
    font-size: calc(10px + 5 * (100vw - 320px) / 1080);
    opacity: 1;
}

.footer-up {
    position: absolute;
    top: 0;
    right: 0;
}

.footer-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid rgb(24, 24, 26,0.4);
    padding: 30px 0;
}

.footer-bottom ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer-bottom ul li {
    text-transform: uppercase;
    padding: 0 20px;
    font-size: calc(8px + 2 * (100vw - 320px) / 1080);
}



/* *** MEDIA QUERIES *** */


/************* max 1250 **************/ @media screen and (max-width:1250px) {


    .footer-middle ul {
        gap: 50px;
    }

    .footer-bottom ul.ct {
        margin: 0;
    }

    .nav-list li, .nav-title {
        font-size: calc(12px + 5 * (100vw - 320px) / 1080);
    }


}

/************* max 950 **************/ @media screen and (max-width:950px) {

    #footer .footer-top {
        gap: 60px;
    }

}

/************* max 850 **************/ @media screen and (max-width:850px) {

    .footer-bottom ul li {
        padding: 0 10px;
    }

}

/************* max 750 **************/ @media screen and (max-width:750px) {

    .ct {
        margin: 0 6%;
    }

    .header-bottom .bn {
        display: none;
    }

    .nav-list > li:last-child {
        display: block;
    }
}

/************* max 550 **************/ @media screen and (max-width:550px) {

    body {
        font-size: calc(10px + 10 * (100vw - 320px) / 1080);
    }

    #footer .footer-top {
        flex-direction: column;
        text-align: center;
    }

    .footer-bottom ul {
        flex-direction: column;
        text-align: center;
    }

    #footer .title-footer {
        font-size: calc(15px + 15 * (100vw - 320px) / 1080);
    }

    .footer-bottom ul li {
        font-size: calc(8px + 10 * (100vw - 320px) / 1080);
        padding: 5px 10px;
    }

    #footer .footer-top > * {
        width: 100%;
    }

    .footer-middle ul li, .footer-right ul li, .footer-address > * {
        font-size: calc(12px + 12 * (100vw - 320px) / 1080);
    }

    #footer .footer-rs {
        justify-content: center;
    }

    .footer-middle ul {
        justify-content: center;
    }

    #footer .title-footer {
        margin-bottom: 30px;
    }

    .nav-wrapper {
        width: 100%;
    }

    .nav-list li, .nav-title {
        font-size: calc(15px + 5 * (100vw - 320px) / 1080);
    }


}


/************* max 450 **************/ @media screen and (max-width:450px) {

    .footer-middle, .footer-right {
        display: none;
    }

    #footer .footer-top {
        text-align: left;
    }

    #footer .footer-rs {
        justify-content: flex-start;
    }

    .footer-bottom ul {
        text-align: left;
        align-items: flex-start;
    }


}