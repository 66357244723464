
.italic {
    font-style: italic;
}

.bolder {
    font-weight: 600;
    opacity: 0.95;
}

.gold {
    color: var(--color_2);
}

.ct {
    margin: 0 10%;
    max-width: 1536px;
}

/* titles */

h1 {
    color: rgb(255,255,255,0.5);
    font-style: italic;
    text-align: right;
}

h2,h3,h4 {
    font-family: "freight-neo-pro", sans-serif;
}

.h2 {
    font-size: calc(25px + 10 * (100vw - 320px) / 1080);
    font-weight: 700;
    margin-bottom: calc(var(--gap_padding) / 4);
    line-height: 1.2;
}

.h3 {
    font-family: "freight-neo-pro", sans-serif;
    font-size: calc(15px + 8 * (100vw - 320px) / 1080);
    letter-spacing: 4px;
    color: var(--color_2);
    text-transform: uppercase;
}

/* data-mail */

[data-mail][data-domain]::after {
    content: attr(data-mail) "@" attr(data-domain);
    cursor: pointer;
}


/* breadcrumbs */

.breadcrumb {
    color: var(--secondary);
    text-transform: uppercase;
    font-size: calc(9px + 1 * (100vw - 320px) / 1080);
    font-weight: 700;
    letter-spacing: .02rem;
}

.breadcrumb ol {
    display: flex;
    justify-content: flex-end;
    text-align: end;
}

.breadcrumb li + li:before {
    content: '/';
    margin: 0 5px;
    transition: .3s ease-in-out;
}

.breadcrumb li a:hover {
    color: var(--primary);
}

/* link */

.link {
    display: inline-block;
    white-space: nowrap;
    color: var(--secondary);
    text-transform: uppercase;
    font-size: calc(10px + 1 * (100vw - 320px) / 1080);
    font-weight: 700;
    letter-spacing: 1px;
}

.link:after {
    content: '';
    height: 1px;
    width: 100%;
    display: block;
    position: absolute;
    left: 0;
    bottom: -2px;
    background-color: var(--secondary);
    transform: scaleX(0);
    transform-origin: left;
    transition: .3s ease-in-out;
}

.link:hover:after {
    transform: scaleX(1);
}


/* smooth scroll */

html.lenis {
    height: auto;
}

.lenis.lenis-smooth {
    scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
    overscroll-behavior: contain;
}

.lenis.lenis-stopped {
    overflow: hidden;
}

.lenis.lenis-scrolling iframe {
    pointer-events: none;
}




/* btn */

.bn {
    position: relative;
    display: block;
    padding: 10px 25px;
    font-weight: 500;
    font-size: calc(11px + 2 * (100vw - 320px) / 1080);
    background: transparent;
    width: fit-content;
    cursor: pointer;
    border-radius: 30px;
    transition: all 0.3s ease 0s;
}

.bn.bn-white {
    border: 2px solid #fff;
    color: #fff;
}

.bn.bn-black {
    border: 2px solid var(--color_1);
    color: var(--color_1);
}

.bn:after {
    content: "";
    position: absolute;
    transition: all 0.3s ease 0s;
    z-index: -1;
}

.bn.bn-white:hover,
.bn.bn-white:focus {
    color: var(--color_1);
}

.bn.bn-black:hover,
.bn.bn-black:focus {
    color: #fff;
}

.bn.bn-white:after {
    background: #fff;
}

.bn.bn-black:after {
    background: var(--color_1);
}

.bn:after {
    top:0;
    bottom: 0;
    left: 0;
    right: 100%;
    border-radius: 30px;
}

.bn:hover,
.bn:focus {
    background-color: transparent;
}
.bn:hover:after,
.bn:focus:after{
    right:0;
}


/* paragraphe */

.paragraphe {
    line-height: 1.4;
    font-size: calc(14px + 1.5 * (100vw - 320px) / 1080);
}

.paragraphe + * {
    margin-top: calc(22px + 1.5%);
}

.paragraphe > * + * {
    margin-top: calc(10px + 1%);
}

.paragraphe li {
    padding-left: 20px;
}

.paragraphe li + li {
    margin-top: 5px
}

.paragraphe ul ul {
    margin: 5px 0 10px;
}

.paragraphe a {
    font-weight: bold;
    transition: .2s;
    white-space: nowrap;
}

.paragraphe a:after {
    content: '';
    height: 1px;
    width: 100%;
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: var(--primary);
    transform: scaleX(0);
    transform-origin: left;
    transition: .3s ease-in-out;
}
.paragraphe a:hover:after {
    transform: scaleX(1);
}


/* captcha */
.grecaptcha-badge {
    display: none;
}

.captcha-mention {
    font-size: calc(9px + 2 * (100vw - 320px) / 1080);
    line-height: 1.2;
}

.captcha-mention > span {
    display: block;
}

.captcha-mention > a {
    opacity: .6;
    transition: .3s;
    font-size: 90%;
}

.captcha-mention > a:hover {
    opacity: 1;
}


/* pagination */

.pagination ol {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.pagination ol {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: calc(var(--gap_padding) - 15px) -15px -15px;
}

.pagination ol li {
    cursor: pointer;
    margin: 15px;
}


/* cookies */
#ck {
    overflow: hidden;
    background-color: var(--color_1);
    color: #fff;
    box-shadow: 0 15px 20px rgba(0, 0, 0, .3);
    border-radius: 3px;
    transition: transform .6s, opacity .6s, visibility .6s;
    transform: translateY(100%);
    opacity: 0;
    visibility: hidden;
    max-width: calc(99vw - 20px);
    position: fixed;
    bottom: calc(10px + .5vw);
    left: calc(10px + .5vw);
    z-index: 9999;
    width: 420px;
    font-size: 11px;
    line-height: 1.3;
}

.cks #ck {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
}

#ck .ckc {
    display: flex;
    align-items: flex-start;
    padding: 20px;
}

#ck p strong {
    display: block;
    margin-bottom: 10px;
    font-size: 120%;
    line-height: 1;
}

#ck a {
    font-weight: bold;
    text-decoration: underline;
}

#ck a:hover {
    color: #eee;
}

#ck i {
    color: #fff;
    font-size: 35px;
    margin-right: 15px;
    top: -3px;
}

#ck i:before {
    animation: cookies 1s infinite linear;
}

#ck .ckb {
    display: flex;
    font-size: 13px;
    font-weight: bold;
}

#ck .ckb > * {
    border-top: 1px solid rgba(0, 0, 0, .1);
    flex: 1;
    background-color: rgba(0, 0, 0, .1);
    padding: 14px 0 15px;
}

#ck .ckb > *:hover {
    background-color: rgba(0, 0, 0, .15);
}

#ck .ckb > * + * {
    border-left: 1px solid rgba(0, 0, 0, .1);
}

#ck .ckb small {
    font-size: 11px;
    font-weight: normal;
}

@keyframes cookies {
    0% {
        content: '\f563';
    }
    100% {
        content: '\f564';
    }
}

/* badge */
.badge {
    white-space: nowrap;
    display: inline-block;
    background: var(--secondary);
    color: var(--white);
    text-transform: uppercase;
    letter-spacing: 0;
    font-size: 9px;
    font-weight: 700;
    padding: 4px 8px 2px 9px;
    border-radius: 4px;
}

/* alert */
.alert {
    background: var(--primary);
    border-radius: 1px;
    color: #fff;
    font-size: 11px;
    font-weight: 400;
    line-height: 1;
    padding: 20px;
}

.alert.alert-danger, .btn.danger, .badge.badge-danger, .badge.danger {
    background: #ff5353;
}

.alert.alert-warning, .btn.warning, .badge.badge-warning, .badge.warning {
    background: #bd6e28;
}

.alert.alert-success, .btn.success, .badge.badge-success, .badge.success {
    background: #8dc111;
}

.alert.alert-info, .btn.info, .badge.badge-info, .badge.info {
    background: var(--secondary);
}

.alert.hide {
    transition: opacity .3s;
}

.alert [data-close-alert] {
    position: absolute;
    top: 2px;
    right: 4px;
    font-size: 10px;
}

.alert.no-close [data-close-alert] {
    display: none;
}

.alert.none {
    display: none;
}


/* push */
[data-push] {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 9999999;
    padding: calc(10px + .5vw);
    pointer-events: none;
}

[data-push] > div {
    margin-top: 10px;
    pointer-events: auto;
    padding: calc(20px + 3 * (100vw - 320px) / 1080);
    font-size: 11px;
    width: 300px;
    max-width: calc(100vw - 40px);
    display: flex;
    background: #fff;
    box-shadow: 0 15px 20px rgba(0, 0, 0, .1);
    border-radius: 3px;
    user-select: none;
    -moz-user-select: none;
}

[data-push] i {
    font-size: 25px;
    margin-right: 15px;
}

[data-push] .success i:before {
    content: '\f058';
    color: #8dc111;
}

[data-push] .error i:before {
    content: '\f057';
    color: #ff5353;
}

[data-push] strong {
    font-weight: bold;
    display: block;
    font-size: 12px;
    margin-bottom: 5px;
}

[data-push] .success strong:before {
    content: 'Succès';
    color: #8dc111;
}

[data-push] .error strong:before {
    content: 'Erreur';
    color: #ff5353;
}

[data-push] button {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 12px;
    opacity: .3;
}

[data-push] > div:hover button {
    opacity: .5;
}

[data-push] button:hover {
    opacity: 1;
}

/* captcha */
.grecaptcha-badge {
    display: none;
}

.captcha-mention {
    font-size: calc(9px + 2 * (100vw - 320px) / 1080);
    line-height: 1.2;
}

.captcha-mention > span {
    display: block;
}

.captcha-mention > a {
    opacity: .6;
    transition: .3s;
    font-size: 90%;
}

.captcha-mention > a:hover {
    opacity: 1;
}

/* share */

.share {
    margin-top: var(--section_padding);
    padding-top: var(--gutter);
    border-top: 1px solid rgba(var(--primary_rgb), .1);
}

.share-wrapper {
    display: flex;
    justify-content: space-between;

}

.share-left ul {
    display: flex;
}

.share-left a {
    display: block;
    --dim: 30px;
    height: var(--dim);
    width: var(--dim);
    --fill: var(--primary);
    --stroke: none;
}

.share-left a:hover {
    --fill: var(--secondary);
}


.wish:hover svg {--fill: var(--primary);}
.wish.on svg {--fill: var(--primary); animation: heartBeat calc(1s * 1.3) ease-in-out;}

@keyframes heartBeat {
    0% {-webkit-transform: scale(1);transform: scale(1);}
    14% {-webkit-transform: scale(1.3);transform: scale(1.3);}
    28% {-webkit-transform: scale(1);transform: scale(1);}
    42% {-webkit-transform: scale(1.3);transform: scale(1.3);}
    70% {-webkit-transform: scale(1);transform: scale(1);}
}



/* *** MEDIA QUERIES *** */


/************* max 550 **************/ @media screen and (max-width:950px) {

    .h3 {
        font-size: calc(15px + 15 * (100vw - 320px) / 1080);
    }
}