*,*:after,*:before{position:relative;box-sizing:border-box;}
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video,button,hr,input,textarea{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline;}
article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block;}
a,button{transition:.3s;cursor:pointer;color:inherit;}
button{border:0;padding:0;background:none;display:inline-block;}
a,a:hover,button,button:hover,*:focus,*:active{text-decoration:none;outline:none;}
body{-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;-webkit-font-smoothing:subpixel-antialiased;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;-webkit-tap-highlight-color:transparent;}
ol,ul{list-style:none;}
blockquote,q{quotes:none;}
blockquote:before,blockquote:after,q:before,q:after{content:'';content:none;}
table{border-collapse:collapse;border-spacing:0;}
strong{font-weight:bold}
img,svg{vertical-align:bottom;height:auto;}
input,textarea{background-color:transparent;resize:none;}
input[type="date"]{cursor:text}
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator{color:rgba(0,0,0,0);background:rgba(0,0,0,0);opacity:0;display:none;}
input::-webkit-datetime-edit-day-field:focus, 
input::-webkit-datetime-edit-month-field:focus, 
input::-webkit-datetime-edit-year-field:focus{background-color:var(--primary);color:var(--primary_txt);outline:none;}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button{-webkit-appearance:none;margin:0;}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button{-webkit-appearance:none;margin:0;}
input[type=number]{-moz-appearance:textfield;}
select{cursor:pointer;border:0;background-color:transparent;-webkit-appearance:none;font-family:inherit;}
select option:hover{background-color:var(--primary);color:var(--primary_txt);}
picture{display:block;}
picture img{max-width:100%;}
em,i{font-style:italic;}
.sf-toolbar{display:none!important;}
[data-link]{cursor:pointer;}
.icon, .icon *{stroke-width:var(--stroke-width)!important;stroke:var(--stroke)!important;fill:none!important;stroke-linejoin:round;stroke-linecap:round;transition: var(--transi);}
html.wait *{cursor:wait!important}

/* scrollbar styling */
*{scrollbar-color:var(--color-primary) var(--grey-3);scrollbar-width:thin;}
*::-webkit-scrollbar{width:3px;background-color:var(--grey-3);}
*::-webkit-scrollbar-thumb{background-color:var(--color-primary);}
html::-webkit-scrollbar{width:4px;background-color:var(--grey-3);}
html{scrollbar-width:thin;scroll-behavior:smooth;}

/* selection styling */
*::-moz-selection{background-color:var(--color_2);color:#fff;}
*::selection{background-color:var(--color_2);color:#fff;}

/* selection placeholder */
::-webkit-input-placeholder{color:inherit;opacity:.5}
::-moz-placeholder{color:inherit;opacity:.5}
::-ms-input-placeholder{color:inherit;opacity:.5}

/* captcha */
.grecaptcha-badge {display: none;}
.captcha-mention {font-size: calc(9px + 2 * (100vw - 320px) / 1080); line-height: 1.2;}
.captcha-mention > span {display: block;}
.captcha-mention > a {opacity: .6; transition: .3s; font-size: 90%;}
.captcha-mention > a:hover {opacity: 1;}

/* cookies */
#ck{overflow:hidden;background-color:var(--color_1);color:#fff;box-shadow:0 15px 20px rgba(0,0,0,.3);border-radius:3px;transition:transform .6s, opacity .6s, visibility .6s;transform:translateY(100%);opacity:0;visibility:hidden;max-width:calc(99vw - 20px);position:fixed;bottom:calc(10px + .5vw);left:calc(10px + .5vw);z-index:9999;width:420px;font-size:11px;line-height:1.3;}
.cks #ck{transform:translateY(0);opacity:1;visibility:visible;}
#ck .ckc{display:flex;align-items:flex-start;padding:20px;}
#ck p {
    color: #fff;
}
#ck p strong{display:block;margin-bottom:10px;font-size:120%;line-height:1;}
#ck a{font-weight:bold;text-decoration:underline;}
#ck a:hover{color:#eee;}
#ck i{color:#fff;font-size:35px;margin-right:15px;top:-3px;}
#ck i:before{animation:cookies 1s infinite linear;}
#ck .ckb{display:flex;font-size:13px;font-weight:bold;}
#ck .ckb > *{border-top:1px solid rgba(0,0,0,.1);flex:1;background-color:rgba(0,0,0,.1);padding:14px 0 15px;}
#ck .ckb > *:hover{background-color:rgba(0,0,0,.15);}
#ck .ckb > * + *{border-left:1px solid rgba(0,0,0,.1);}
#ck .ckb small{font-size:11px;font-weight:normal;}
@keyframes cookies{0%{content:'\f563';}100%{content:'\f564';}}


