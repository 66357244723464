:root {

    --color_1: #18181A;
    --color_2: #a2854c;

    --font_1: "freight-neo-pro", sans-serif;

    --section_padding: calc(35px + 40 * (100vw - 320px) / 1080);
    --container_padding: clamp(2.5em, 8vw, 8em);
    --gap_padding: clamp(1.5em, 4vw, 2.5em);
    --gutter: calc(15px + 10 * (100vw - 320px) / 1080);
    --gutter-min: calc(10px + 10 * (100vw - 320px) / 1080);

    /* header */

    --header-height: calc(var(--header-top) + var(--header-bottom));
    --header-top: clamp(20px, 5vh, 30px);
    --header-bottom: clamp(70px, 15vh, 120px);

    /* transitions */

    --transition_open: .5s cubic-bezier(0, .97, 0, .97);

    /* shadows */

    --shadow-section: 1px 1px 12px -3px rgba(0, 0, 0, .05);

    /* all */

    --height_toolbar: 65px;
}
